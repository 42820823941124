import React, { useEffect, useState, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import VariationModal from "../../Components/Modals/variation_modal";
import { BrowserView, MobileView } from "react-device-detect";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { ApiService } from "../../Components/Services/apiservices";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import constant from "../../Components/Services/constant";
import { ToastContainer, toast } from "react-toastify";
import Productlistsidebar from "../../Components/Elements/product_list_sidebar";
import MobileHeader from "../../Components/Elements/mobile_header";
import QuickViewModal from "../../Components/Modals/quick_view_modal";
import sessionCartData from "../../Components/Elements/cart_session_data";
import StarRating from "../../Components/Elements/starrating";
import Modal from "react-bootstrap/Modal";
import multiCurrency from "../../Components/Elements/multi_currrency";
import { Helmet } from "react-helmet";
import DataContext from "../../Components/Elements/eventContext";
import ProductBox from "../../Components/Elements/product_box";


function Collection() {
  const didMountRef = useRef(true);
  const dataArray = sessionCartData();
  const parsedCartSession = dataArray[1];
  const [resProductsData, setProductsData] = useState([]);
  const [cartCount, setCartCount] = useState(parsedCartSession.length);
  const [loadingMore, setLoadingMore] = useState(false);
  const [currentNumber, setCurrentNumber] = useState(1);
  const [LastNumber, setLastNumber] = useState(100);
  const [sortingSession, setSortingSession] = useState("")
  const [loading, setLoading] = useState();
  const [totalLength, setTotalLength] = useState("");
  const { type, slug } = useParams();
  const [setSession, SetSession] = useState("");
  const [productData, setProductData] = useState(null);
  const [helmetData, setHelmetData] = useState("")
  const [showSort, setShowSort] = useState(false);
  const [filterData, setFilterData] = useState(JSON.parse(localStorage.getItem('FILTER_SESSION')) || '')
  const [CollectionFilter, setCollectionFilter] = useState(localStorage.getItem('collection') || '')
  const [CategoryFilter, setCategoryFilter] = useState(localStorage.getItem('category') || '')
  const [TagFilter, setTagFilter] = useState(localStorage.getItem('tag') || '')
  const [imageUrl, setImageUrl] = useState("")
  const [resCollectionList, setResCollectionList] = useState([])
  const [aryCategoryList, setCategoryList] = useState([]);
  const [resAttributesList, setAttributesList] = useState([]);
  const [showType, setShowType] = useState('');
  const [MobFilterModal, setMobFilterModal] = useState('');
  const [selectedTab, setSelectedTab] = useState("tab-collection");

  const handleShowSortModal = () => {
    setShowSort(true);
  };
  const [showVariation, setShowVariation] = useState(false);

  const handleChildVariationModalData = (status) => {
    const dataArray = sessionCartData();
    const parsedCartSession = dataArray[1];
    setCartCount(parsedCartSession.length)
    setShowVariation(status);
    // onParentData(status)
  };
  const [showQuick, setShowQuick] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleChildData = (status) => {
    setShow(status);
  };

  const handleShowQuickModal = (data) => {
    setProductData(data);
    setShowQuick(true);
  };
  const handleChildQuickModalData = (status) => {
    setShowQuick(status);
  };

  useEffect(() => {
    if (didMountRef.current) {
      setLoading(true);
      /* localStorage.removeItem('collection')
      localStorage.removeItem('category')
      localStorage.removeItem('tag')
      localStorage.removeItem('FILTER_SESSION')
      localStorage.removeItem('SORT_SESSION')
      localStorage.removeItem('RANGE_SESSION') */
      SetSession(localStorage.getItem("USER_TOKEN"));
      getProductListSidebar();
      var typecollection = ""
      if (type) {
        typecollection = type
      } else {
        typecollection = "collection"
      }
      setShowType(typecollection);
      const dataString = {
        type: typecollection,
        cat_slug: slug,
      };
      console.log("typecollection", typecollection);
      ApiService.postData("category-detail", dataString).then((res) => {
        if (res.status === "success") {
          setHelmetData(res.hemlet);
          setImageUrl(res.imageurl)
          if (slug && slug !== "all") {
            if (typecollection == 'category') {
              setCategoryFilter(res.hemlet.name)
              localStorage.setItem(typecollection, res.hemlet.name);
              getfilterproducts();
            } else if (typecollection == 'collection') {
              setCollectionFilter(res.hemlet.name)
              localStorage.setItem(typecollection, res.hemlet.name);
              getfilterproducts();
            } else {
              setTagFilter(res.hemlet.name);
              localStorage.setItem(typecollection, res.hemlet.name);
              typecollection = type
              getfilterproducts(null, res.hemlet.name);
            }
          } else {
            getfilterproducts();
          }
        }
      });

    }
    didMountRef.current = false;
  }, []);
  const contextValues = useContext(DataContext)
  const loginModal = () => {
    contextValues.setToggleLoginModal(!contextValues.toggleLoginModal)
  }
  const getProductListSidebar = () => {
    ApiService.fetchData("get-product-list-sidebar").then((res) => {
      if (res.status === "success") {
        setAttributesList(res.resAttributesList);
        setCategoryList(res.aryCategoryList);
        setResCollectionList(res.resCollectionList)
      } else {
      }
    });
  };

  const handleCheckboxChange = (checkboxKey, checkboxValue, removedefault = 0) => {
    setFilterData((prevSelectedCheckboxes) => {
      const updatedCheckboxes = { ...prevSelectedCheckboxes };
      if (updatedCheckboxes[checkboxKey]) {
        const existingValues = updatedCheckboxes[checkboxKey];
        if (existingValues.includes(checkboxValue) && removedefault == 0) {
          updatedCheckboxes[checkboxKey] = existingValues.filter(
            (id) => id !== checkboxValue
          );
        } else if (existingValues.includes(checkboxValue) && removedefault == 1) {
        } else {
          updatedCheckboxes[checkboxKey] = [...existingValues, checkboxValue];
        }
      } else {
        updatedCheckboxes[checkboxKey] = [checkboxValue];
      }
      localStorage.setItem('FILTER_SESSION', JSON.stringify(updatedCheckboxes));
      getfilterproducts();
      return updatedCheckboxes;
    });
    window.location.reload();
  };

  const handleCheckboxChangeMoblile = (checkboxKey, checkboxValue, removedefault = 0) => {
    setFilterData((prevSelectedCheckboxes) => {
      const updatedCheckboxes = { ...prevSelectedCheckboxes };
      if (updatedCheckboxes[checkboxKey]) {
        const existingValues = updatedCheckboxes[checkboxKey];
        if (existingValues.includes(checkboxValue) && removedefault == 0) {
          updatedCheckboxes[checkboxKey] = existingValues.filter(
            (id) => id !== checkboxValue
          );
        } else if (existingValues.includes(checkboxValue) && removedefault == 1) {
        } else {
          updatedCheckboxes[checkboxKey] = [...existingValues, checkboxValue];
        }
      } else {
        updatedCheckboxes[checkboxKey] = [checkboxValue];
      }
      return updatedCheckboxes;
    });
  };

  const addtofav = (productId) => {
    const dataString = {
      product_id: productId,
    };
    ApiService.postData("add-to-fav", dataString).then((res) => {
      if (res.data.status == "success") {
        var element = document.getElementById("wishlisticon" + productId);
        element.classList.remove("d-icon-heart", "d-icon-heart-full");
        element.classList.add(res.data.notification);
        if (res.data.notification === "d-icon-heart") {
          toast.success("Removed from wishlist");
        } else {
          toast.success("Added to Wishlist");
        }
      }
    });
  };

  /* const getfilterproducts = (sort = null, tag = null) => {
    let pageNumber;
    if (resProductsData.length >= 12) {
      pageNumber = Math.ceil(resProductsData.length / 12) + 1;
    }
    else {
      pageNumber = 1
    }
    if (sort != null) {
      localStorage.setItem('SORT_SESSION', sort);
    }
    let sortsession = ""
    if (localStorage.getItem('SORT_SESSION') && localStorage.getItem('SORT_SESSION') != null) {
      sortsession = localStorage.getItem('SORT_SESSION');
      setSortingSession(localStorage.getItem('SORT_SESSION'))
    } 
    const dataString = {
      filterArray: slug == "all" && JSON.parse(localStorage.getItem('FILTER_SESSION')) == null ? [] : JSON.parse(localStorage.getItem('FILTER_SESSION')),
      sort: sortsession ? sortsession : "",
      collection: localStorage.getItem('collection') || '',
      category: localStorage.getItem('category') || '',
      tag: tag || ''
    };
    ApiService.filterPostData("getfilterproductsdata" + "?page=" + pageNumber, dataString).then((res) => {
      if (res.status === "success") {
        if (sort != null) {
          setProductsData(res.data.data);
        } else {
          if (res.data.data.length > 0) {
            const apires = res.data.data;
            var mergeData;
            if (pageNumber == 1) {
              mergeData = apires;
            } else {
              mergeData = [...resProductsData, ...apires];
            }
            setProductsData(mergeData);
            setTotalLength(res.data.total);
            setLoading(false);
          } else {
            setProductsData(res.data.data);
            setLoading(false);
          }
        }
      } else {
        // Handle error
      }
    });
  }; */

  const getfilterproducts = (sort = null, tag = null) => {
    if (loadingMore) return; // Prevent multiple calls
    setLoadingMore(true);
    let currentPage = currentNumber;
    let pageNumber = Math.ceil(resProductsData.length / 12) + 1;

    if (sort != null) {
      localStorage.setItem('SORT_SESSION', sort);
      currentPage = 1;
      setLastNumber(1);
    }
    let sortsession = "";
    if (localStorage.getItem('SORT_SESSION')) {
      sortsession = localStorage.getItem('SORT_SESSION');
      setSortingSession(sortsession);
    }

    const dataString = {
      filterArray: slug === "all" && !localStorage.getItem('FILTER_SESSION') ? [] : JSON.parse(localStorage.getItem('FILTER_SESSION')),
      sort: sortsession || "",
      collection: localStorage.getItem('collection') || '',
      category: localStorage.getItem('category') || '',
      tag: tag || ''
    };
    if (currentNumber <= LastNumber) {
      ApiService.filterPostData(`getfilterproductsdata?page=${currentPage}`, dataString).then((res) => {
        if (res.status === "success") {
          if (sort != null) {
            setProductsData(res.data.data);
          } else {
            const apires = res.data.data;
            if (apires.length > 0) {
              const mergeData = [...resProductsData, ...apires];
              setProductsData(mergeData);
              setTotalLength(res.data.total);
              setCurrentNumber(currentPage + 1);
              setLastNumber(res.data.last_page);
            } else {
              setProductsData(apires);
            }
          }
          setLoading(false);
        } else {
          setLoading(false);
        }
        setLoadingMore(false); // Allow next load
      }).catch(() => {
        setLoadingMore(false); // Allow next load in case of error
      });
    }
  };


  const handleClosesort = () => {
    setShowSort(false)
  };

  const removefilterdata = (key, value) => {
    const array = JSON.parse(localStorage.getItem('FILTER_SESSION'));
    const newArray = array[key].filter(item => item !== value);
    array[key] = newArray
    localStorage.setItem('FILTER_SESSION', JSON.stringify(array));
    window.location.reload()
  }

  const resetFilter = () => {
    localStorage.removeItem('FILTER_SESSION')
    localStorage.removeItem('SORT_SESSION')
    localStorage.removeItem('collection')
    localStorage.removeItem('category')
    localStorage.removeItem('tag')
    window.location.reload()
  }

  const handleFilterCollection = (value) => {
    if (CollectionFilter == value.cat_name) {
      setCollectionFilter('')
      localStorage.removeItem('collection');
      window.location.reload();
    } else {
      window.location.href = '/collection/' + value.cat_slug;
    }
  };

  const handleFilterCategory = (value) => {
    if (CategoryFilter == value.cat_name) {
      setCategoryFilter('')
      localStorage.removeItem('category');
      window.location.reload();
    } else {
      setCategoryFilter(value.cat_name)
      localStorage.setItem('category', value.cat_name);
      window.location.reload();
    }
  };

  const handleTabChange = (id) => {
    setSelectedTab(id);
  };

  const clickOnApplyMobile = () => {
    localStorage.setItem('category', CategoryFilter);
    localStorage.setItem('collection', CollectionFilter);
    localStorage.setItem('FILTER_SESSION', JSON.stringify(filterData));
    getfilterProductsMobile();
    setMobFilterModal(false);
  }

  const getfilterProductsMobile = (sort = null, tag = null) => {
    let pageNumber = 1
    if (sort != null) {
      localStorage.setItem('SORT_SESSION', sort);
    } else {
      localStorage.removeItem('tag')
    }
    let sortsession = ""
    if (localStorage.getItem('SORT_SESSION') && localStorage.getItem('SORT_SESSION') != null) {
      sortsession = localStorage.getItem('SORT_SESSION');
      setSortingSession(localStorage.getItem('SORT_SESSION'))
    }
    // setTimeout(() => {
    const dataString = {
      filterArray: slug == "all" && JSON.parse(localStorage.getItem('FILTER_SESSION')) == null ? [] : JSON.parse(localStorage.getItem('FILTER_SESSION')),
      sort: sortsession ? sortsession : "",
      collection: localStorage.getItem('collection') || '',
      category: localStorage.getItem('category') || '',
      tag: localStorage.getItem('tag') || ''
    };
    setLoading(true)
    ApiService.filterPostData("getfilterproductsdata" + "?page=" + pageNumber, dataString).then((res) => {
      if (res.status === "success") {
        if (sort != null) {
          setProductsData(res.data.data);
          setLoading(false);
        } else {
          if (res.data.data.length > 0) {
            setProductsData(res.data.data);
            setTotalLength(res.data.total);
            setLoading(false);
          } else {
            setProductsData(res.data.data);
            setLoading(false);
          }
        }
      } else {
        setLoading(false);
        // Handle error
      }
    });
  };

  return (
    <>
      <Helmet>
        <title>{helmetData.title}</title>
        <meta name="description" itemprop="description" content={helmetData.meta_description != null ? helmetData.meta_description : "Ashna Vaswani"} />
        <meta name="keywords" content={helmetData.keywords ? helmetData.keywords : "Ashna Vaswani"} />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={helmetData.title ? helmetData.title : "Ashna Vaswani"} />
        <meta property="og:image" content={helmetData.banner ? imageUrl + helmetData.banner : constant.LOGO_IMAGE }/>
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content={helmetData.meta_description != null ? helmetData.meta_description : "Ashna Vaswani"} />
        <meta name="twitter:url" content={window.location.href} />
        <meta name="twitter:title" content={helmetData.title ? helmetData.title : "Ashna Vaswani"} />
        <meta name="twitter:description" content={helmetData.meta_description != null ? helmetData.meta_description : "Ashna Vaswani"} />
        <meta property="twitter:image" content={helmetData.banner ? imageUrl + helmetData.banner : constant.LOGO_IMAGE }/>
      </Helmet> 

      <BrowserView>
        <Header state="inner-header" />
        <main>
          <div className="page-content mt-5 mb-5">
            <Container>
              <Row>
                <Col lg={3} className="siderbar-filter">
                  <aside>
                    <div className="filterbox mb-3">
                      <div className="filterbox-title">Shop by Collection</div>
                      <div className="filterbox-body filter-common filter-height">
                        <div className="filter-list">
                          <ul>
                            {resCollectionList.length > 0 &&
                              resCollectionList.map((value) => (
                                <li key={value.cat_id} >
                                  <input
                                    type="checkbox"
                                    onClick={(e) =>
                                      handleFilterCollection(value)
                                    }
                                    checked={CollectionFilter == value.cat_name}
                                  />
                                  <span>{value.cat_name}</span>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="filterbox mb-3">
                      <div className="filterbox-title">Shop by Category</div>
                      <div className="filterbox-body filter-common filter-height">
                        <div className="filter-list">
                          <ul>
                            {aryCategoryList.length > 0 &&
                              aryCategoryList.map((value) => {
                                if (value.children) {
                                  return (
                                    <li key={value.cat_id}>
                                      <input
                                        type="checkbox"
                                        onChange={(e) =>
                                          handleFilterCategory(value)
                                        }
                                        checked={CategoryFilter == value.cat_name}
                                      />
                                      <span>{value.cat_name}</span>
                                    </li>
                                  );
                                } else {
                                  return (
                                    <li key={value.cat_id}>
                                      <input
                                        type="checkbox"
                                        onChange={(e) =>
                                          handleFilterCategory(value)
                                        }
                                        checked={CategoryFilter == value.cat_name}
                                      />
                                      <span>{value.cat_name}</span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div>
                      </div>
                    </div>
                    {resAttributesList.length > 0 &&
                      resAttributesList.map((value) => (
                        <div className="filterbox mb-3">
                          <div className="filterbox-title">{value.attribute_name}</div>
                          <div className="filterbox-body filter-common filter-height">
                            <div className="filter-list">
                              <ul>
                                {value.attributeterms &&
                                  value.attributeterms.length > 0 &&
                                  value.attributeterms.map((subvalue) => (
                                    <li key={subvalue.terms_id}>
                                      <input
                                        type="checkbox"
                                        className="attributecheckbox"
                                        onChange={() =>
                                          handleCheckboxChange(
                                            value.attribute_name,
                                            subvalue.terms_name
                                          )
                                        }
                                        checked={filterData[
                                          value.attribute_name
                                        ]?.includes(subvalue.terms_name)}
                                      />
                                      <span>{subvalue.terms_name}</span>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      ))}
                  </aside>
                </Col>

                <Col lg={9}>
                  {helmetData && (
                    <>
                      {loading === true ? (
                        <div className="cat-add-banner mb-3">
                          <Skeleton width="100%" height={300} />
                        </div>
                      ) : (
                        helmetData.banner && (
                          <div className="cat-add-banner mb-3">
                            <img
                              src={imageUrl + helmetData.banner}

                              style={{ width: "100%" }}
                            />
                          </div>
                        )
                      )}
                    </>
                  )}
                  <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>
                      {helmetData.name || type || 'Collection'}
                    </Breadcrumb.Item>
                  </Breadcrumb>
                  <div className="page-title mb-2">
                    <h2 className="mb-0">
                      {helmetData.name || type || 'Collection'}
                    </h2>
                    <p>{helmetData.description}</p>
                  </div>
                  <div className="filterselect" style={{ marginBottom: "10px" }}>
                    <div className="filterselectlist">
                      <ul>
                        {CollectionFilter ? <li>{CollectionFilter || ''}{showType != 'collection' ? <i className="fa fa-times" aria-hidden="true" onClick={() => { localStorage.removeItem('collection'); window.location.reload(); }}></i> : ''} </li> : ''}

                        {CategoryFilter ? <li>{CategoryFilter || ''}{showType != 'category' ? <i className="fa fa-times" aria-hidden="true" onClick={() => { localStorage.removeItem('category'); window.location.reload(); }}></i> : ''} </li> : ''}
                        {localStorage.getItem('FILTER_SESSION') != null ?
                          Object.keys(JSON.parse(localStorage.getItem('FILTER_SESSION'))).map((key) => (
                            JSON.parse(localStorage.getItem('FILTER_SESSION'))[key].map((value) => (
                              <li> {value} <i className="fa fa-times" aria-hidden="true" onClick={() => { removefilterdata(key, value) }}></i></li>
                            ))
                          ))
                          :
                          ""}
                      </ul>
                    </div>
                    {filterData || (CategoryFilter && showType != 'category') || (CollectionFilter && showType != 'collection') ?
                      <button className="resetbutton" onClick={resetFilter}>Reset Filter</button> : ''}
                  </div>

                  <div className="shortby">
                    <div className="productcount">
                      Showing results of {totalLength} products.
                    </div>
                    <div className="shortbydrop">
                      <div className="shortbydrop-inner select">
                        <label>Sort by</label>
                        <select
                          onChange={(e) => getfilterproducts(e.target.value)}

                        >
                          <option value="">Select</option>
                          <option value="featured" selected={sortingSession == "featured" ? true : ""}>Featured</option>
                          <option value="bestselling" selected={sortingSession == "bestselling" ? true : ""}>Best selling</option>
                          <option value="alphaasc" selected={sortingSession == "alphaasc" ? true : ""}>Alphabetically, A-Z</option>
                          <option value="alphadesc" selected={sortingSession == "alphadesc" ? true : ""}>Alphabetically, Z-A</option>
                          <option
                            value="asc"
                            selected={sortingSession == "asc" ? true : ""}

                          >
                            Price, low to high
                          </option>
                          <option
                            value="desc"
                            selected={sortingSession == "desc" ? true : ""}
                          >
                            Price, high to low
                          </option>
                          <option value="dateasc" selected={sortingSession == "dateasc" ? true : ""}>Date, old to new</option>
                          <option value="datedesc" selected={sortingSession == "datedesc" ? true : ""}>Date, new to old</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <InfiniteScroll
                    dataLength={resProductsData.length}
                    next={getfilterproducts}
                    hasMore={true}
                    endMessage={<p>No more data to load.</p>}
                    style={{ overflow: "hidden !important" }}
                  >
                    {loading === true ? (
                      <Row >
                        {[...Array(9)].map((_, index) => (
                          <Col lg={3}>
                            <div className="product" key={index}>
                              <figure className="product-media">
                                <Skeleton
                                  variant="text"
                                  width={280}
                                  height={315}
                                />
                              </figure>
                              <div className="product-details">
                                <h3 className="product-name">
                                  <Skeleton variant="text" width={150} />
                                </h3>
                                <div className="product-price">
                                  <Skeleton variant="text" width={150} />
                                </div>
                              </div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    ) : (
                      <>
                        {resProductsData.length > 0 ? (
                          <Row>
                            {resProductsData.map((value, indexProduct) => {
                              const productPrice = value.product_price;
                              const productSellingPrice =
                                value.product_selling_price;
                              const discount = (
                                ((productPrice - productSellingPrice) /
                                  productPrice) *
                                100
                              ).toFixed(0);
                              return (
                                <Col lg={3} key={indexProduct}>
                                  <ProductBox productValue={value} indexProduct={indexProduct} classType="product mb-5" key={indexProduct} />
                                </Col>
                              );
                            })}
                          </Row>
                        ) : (

                          <h2>No Products found.</h2>
                        )}
                      </>
                    )}
                  </InfiniteScroll>
                </Col>
              </Row>
            </Container>
          </div>
        </main>
        <Footer />
      </BrowserView>

      <MobileView>
        <MobileHeader PageName={helmetData.name || type || 'Collection'} />
        <main className="main">
          <div className="page-content mt-3 mb-5">
            <Container>
              <InfiniteScroll
                dataLength={resProductsData.length}
                next={getfilterproducts}
                hasMore={true}
                endMessage={<p>No more data to load.</p>}
                style={{ overflow: "hidden !important" }}
              >
                {loading === true ? (
                  <div className="row g-3">
                    {[...Array(9)].map((_, index) => (
                      <div className="col-6" key={index}>
                        <div className="product" key={index}>
                          <figure className="product-media">
                            <Skeleton
                              variant="text"
                              width={"100%"}
                              height={170}
                            />
                          </figure>
                          <div className="product-details">
                            <div className="product-details-inner">
                              <h3 className="product-name">
                                <Skeleton
                                  variant="text"
                                  width={120}
                                  height={15}
                                />
                              </h3>
                              <div className="product-price">
                                <Skeleton variant="text" width={80} height={15} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <>
                    {resProductsData.length > 0 ? (
                      <div className="row g-2">
                        {resProductsData.map((value, indexProduct) => {
                          const productPrice = value.product_price;
                          const productSellingPrice =
                            value.product_selling_price;
                          const discount = (
                            ((productPrice - productSellingPrice) /
                              productPrice) *
                            100
                          ).toFixed(0);
                          return (
                            <div className="col-6" key={indexProduct}>
                              <ProductBox productValue={value} indexProduct={indexProduct} classType="product" />
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <h2>No More Products found.</h2>
                    )}
                  </>
                )}
              </InfiniteScroll>
            </Container>
            <div className="mfb-section">
              <div className="mfb-section-inner">
                <div
                  className="mfb-box border-right"
                  onClick={handleShowSortModal}
                >
                  <i className="d-icon-arrow-up"></i>
                  <i className="d-icon-arrow-down me-2"></i>Sort By
                </div>
                <div className="mfb-box" onClick={() => setMobFilterModal(true)}>
                  <i className="d-icon-filter-2 me-2"></i>Filter
                </div>
              </div>
            </div>
          </div>
        </main>
        <Modal
          show={MobFilterModal}
          onHide={() => setMobFilterModal(false)}
          className="filterModal bottom"
        >
          <div className="filterModal-section">
            <div className="filterModal-header">
              <h6 className="tx-14 mb-0">Filters</h6>
              <a href="javascript:void(0)"
                onClick={resetFilter}
                className="tx-12 tx-theme">
                CLEAR ALL
              </a>
            </div>
            <div className="filterModal-body filter-height">
              <div className="filter_tabs">
                <div className="filter_tab">
                  <input
                    type="radio"
                    id="tab-collection"
                    name="tab-group-1"
                    checked={selectedTab === "tab-collection"}
                    onChange={() => handleTabChange("tab-collection")}
                  />
                  <label for="tab-collection">Collection</label>
                  <div className="filter_content">
                    <div className="fiter_content_list">
                      <ul>
                        {resCollectionList.length > 0 &&
                          resCollectionList.map((value) => (
                            <li
                              key={value.cat_id}
                              onChange={(e) =>
                                setCollectionFilter(value.cat_name)
                              }
                            >
                              <span>{value.cat_name}</span>
                              <input
                                type="checkbox"
                                checked={CollectionFilter == value.cat_name}
                              />
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="filter_tab">
                  <input
                    type="radio"
                    id="tab-0"
                    name="tab-group-1"
                    checked={selectedTab === "tab-0"}
                    onChange={() => handleTabChange("tab-0")}
                  />
                  <label for="tab-0">Category</label>
                  <div className="filter_content">
                    <div className="fiter_content_list">
                      <ul>
                        {aryCategoryList.length > 0 &&
                          aryCategoryList.map((value) => (
                            <li
                              key={value.cat_id}
                              onChange={(e) =>
                                setCategoryFilter(value.cat_name)
                              }
                            >
                              <span>{value.cat_name}</span>
                              <input
                                type="checkbox"
                                checked={CategoryFilter == value.cat_name}
                              />
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
                {resAttributesList.length > 0 &&
                  resAttributesList.map((value, index) => (
                    <div className="filter_tab">
                      <input
                        type="radio"
                        id={"tab-" + index + 1}
                        name="tab-group-1"
                        checked={selectedTab === "tab-" + (index + 1)}
                        onChange={() => handleTabChange("tab-" + (index + 1))}
                      />
                      <label for={"tab-" + index + 1}>
                        {value.attribute_name}
                      </label>
                      <div className="filter_content">
                        <div className="fiter_content_list">
                          <ul>
                            {value.attributeterms &&
                              value.attributeterms.length > 0 &&
                              value.attributeterms.map((subvalue) => (
                                <li
                                  key={subvalue.terms_id}
                                  onChange={() =>
                                    handleCheckboxChangeMoblile(
                                      value.attribute_name,
                                      subvalue.terms_name
                                    )
                                  }
                                >
                                  <span>{subvalue.terms_name}</span>
                                  <input
                                    type="checkbox"
                                    checked={filterData[
                                      value.attribute_name
                                    ]?.includes(subvalue.terms_name)}
                                  />
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="filterModal-footer">
              <span className="border-right" onClick={() => setMobFilterModal(false)}>
                CLOSE
              </span>
              <span className="tx-theme" onClick={clickOnApplyMobile}>
                APPLY
              </span>
            </div>
          </div>
        </Modal>
      </MobileView>
      {showQuick && (
        <QuickViewModal
          showmodal={showQuick}
          productdata={productData}
          onChildData={handleChildQuickModalData}
        />
      )}
      {showSort && (
        <Modal show={showSort} onHide={handleClosesort} className="sortModal bottom">
          <div className="sortModalbox">
            <button onClick={handleClosesort} className="pop-close"><i className="d-icon-times"></i></button>
            <ul>
              <li onClick={(e) => { getfilterProductsMobile('featured'); setShowSort(false); }}>
                <span>Featured</span>
                <input type="checkbox" value="featured" checked={sortingSession == 'featured' ? true : false} />
              </li>
              <li onClick={(e) => { getfilterProductsMobile('bestselling'); setShowSort(false); }}>
                <span>Best selling</span>
                <input type="checkbox" value="bestselling" checked={sortingSession == 'bestselling' ? true : false} />
              </li>
              <li onClick={(e) => { getfilterProductsMobile('alphaasc'); setShowSort(false); }}>
                <span>Alphabetically, A-Z</span>
                <input type="checkbox" value="alphaasc" checked={sortingSession == 'alphaasc' ? true : false} />
              </li>
              <li onClick={(e) => { getfilterProductsMobile('alphadesc'); setShowSort(false); }}>
                <span>Alphabetically, Z-A</span>
                <input type="checkbox" value="alphadesc" checked={sortingSession == 'alphadesc' ? true : false} />
              </li>
              <li onClick={(e) => { getfilterProductsMobile('asc'); setShowSort(false); }}>
                <span>Price, low to high</span>
                <input type="checkbox" value="asc" checked={sortingSession == 'asc' ? true : false} />
              </li>
              <li onClick={(e) => { getfilterProductsMobile('desc'); setShowSort(false); }}>
                <span>Price, high to low</span>
                <input type="checkbox" value="desc" checked={sortingSession == 'desc' ? true : false} />
              </li>
              <li onClick={(e) => { getfilterProductsMobile('dateasc'); setShowSort(false); }}>
                <span>Date, old to new</span>
                <input type="checkbox" value="dateasc" checked={sortingSession == 'dateasc' ? true : false} />
              </li>
              <li onClick={(e) => { getfilterProductsMobile('datedesc'); setShowSort(false); }}>
                <span>Date, new to old</span>
                <input type="checkbox" value="datedesc" checked={sortingSession == 'datedesc' ? true : false} />
              </li>
            </ul>
          </div>
        </Modal>

      )}

      {showVariation && (
        <VariationModal
          showvariationmodal={showVariation}
          productdata={productData}
          onChildData={handleChildVariationModalData}
        />
      )}
    </>
  );
}
export default Collection;
